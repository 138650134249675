import React from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"

export default class Mailing extends React.Component{
    constructor() {
        super()
        this.state = {
            email: '',
            message: ''
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const result = await addToMailchimp(this.state.email);
        this.setState({ message: result.msg });
        console.log(result)
    };

    handleInputChange = (event) => {
        this.setState({
            email: event.target.value
        });
    };

    render() {

        return (
            <Wrapper>
                <div className="flex-container mailing-list ">
                    <form onSubmit={this.handleSubmit}>
                    <h4 className="text-color-black subhead-s text-align-center">Join Our Mailing List!</h4>
                        <div className="input-area">
                        <div className="text-color-red body-text-l" dangerouslySetInnerHTML={{ __html: `${this.state.message}`}} />
                        <input
                            type="email"
                            name="email"
                            aria-label="Email"
                            required
                            autoComplete="off"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                        
                        <label className="label-name" for="email">
                            <span className="content-name text-color-white">Email</span>
                        </label>
                        </div>

                        <div className="input-area button-area">
                        <button 
                            label="Send Contact Form" 
                            type="submit" 
                            className="button"
                            >
                            Submit
                            </button>
                        
                        </div>
                    </form>
                </div>
            </Wrapper>
        );
    }
}
const Wrapper = styled.div`
mailing-list: width:100%;
position: relative;
margin: 10% 0;

.text-align-center{
  padding-bottom: 2%;
  text-align: left;

  @media only screen and (max-width: 767px){
    text-align: center;
  }
}
`